import Footer from "./Footer";
import Header from "./Header";
import { Outlet, Link } from "react-router-dom";

export const DefaultLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
