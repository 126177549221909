import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";

const Header = () => {
  return (
    <>
      <header class="">
        <nav
          class="navbar is-primary has-text-white"
          role="navigation"
          aria-label="main navigation"
        >
          <div class="navbar-brand">
            <Link class="navbar-item" to="/map">
              <img src={Logo} width="112" height="28" />
            </Link>
            <Link class="navbar-item nav-menu-right" to="/search">
              <span class="icon">
                <i class="fas fa-search"></i>
              </span>
              <span>ワインから探す</span>
            </Link>
          </div>
          <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-start"></div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
