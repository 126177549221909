import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import ReactLoading from "react-loading";
import { Link, useParams } from "react-router-dom";
import ic_area from "../assets/ic_area.png";
import defaultshopimage from "../assets/shop_default.png";

import WhatGirouette from "./WhatGirouette";
import { Tablet, Desktop, LargeDesktop, Mobile } from "./Responsive";
import * as CONSTANTS from "../datas/constants";
import LoadingOverlay from "react-loading-overlay";

const SearchByWine = () => {
  const [loading, setLoading] = useState(true);

  const { nameid } = useParams();
  const [id, winename] = nameid.split(":");

  const init = {
    name: "未選択",
    id: -1,
  };

  const [shops, setShops] = useState([]);
  const [totalshop, setTotalShop] = useState(0);

  const [largeareas, setLargereas] = useState([
    {
      name: "都道府県を選択",
      id: -1,
    },
  ]);
  const [selectlargearea, setSelectLargeArea] = useState({});
  const [middleareas, setMiddlereas] = useState([
    {
      name: "中エリアを選択",
      id: -1,
    },
  ]);
  const [selectmiddlearea, setSelectMiddleArea] = useState({});
  const [smallareas, setSmallreas] = useState([
    {
      name: "小エリアを選択",
      id: -1,
    },
  ]);
  const [selectsmallarea, setSelectSmallArea] = useState({});

  // Large Area Code
  useEffect(() => {
    const fetchLargeArea = async () => {
      var result = await axios.get(
        `${CONSTANTS.BACKENDURL}/api/large_area/v1`,
        {
          headers: { "x-api-key": CONSTANTS.APIKEY },
        }
      );
      //  = [...result.results.large_area];
      // // console.log(result);
      setLargereas([...largeareas, ...result.data.results.large_area]);
    };
    fetchLargeArea();
    setLoading(false);
  }, []);

  // Middle Area Code
  const fetchMiddleArea = async (code) => {
    setLoading(true);

    var params = {
      large_area: code,
    };
    var result = await axios.get(`${CONSTANTS.BACKENDURL}/api/middle_area/v1`, {
      params,
      headers: { "x-api-key": CONSTANTS.APIKEY },
    });
    //  = [...result.results.large_area];
    // if (middleareas.length > 2){
    //   setMiddlereas()
    // }
    setMiddlereas([init, ...result.data.results.middle_area]);
    setLoading(false);
  };

  // Middle Area Code
  const fetchSmallArea = async (code) => {
    setLoading(true);

    var params = {
      large_area: selectlargearea.code,
      middle_area: code,
    };
    var result = await axios.get(`${CONSTANTS.BACKENDURL}/api/small_area/v1`, {
      params,
      headers: { "x-api-key": CONSTANTS.APIKEY },
    });
    //  = [...result.results.large_area];
    setSmallreas([init, ...result.data.results.small_area]);
    setLoading(false);
  };

  const StartSearch = async () => {
    setLoading(true);

    const params = {
      wine_id: id,
    };
    if (selectlargearea) {
      params["large_area_code"] = selectlargearea.code;
    }
    if (selectmiddlearea) {
      params["middle_area_code"] = selectmiddlearea.code;
    }
    if (selectsmallarea) {
      params["small_area_code"] = selectsmallarea.code;
    }

    const URL = `${CONSTANTS.BACKENDURL}/api/v1/shops`;
    const response = await axios.get(URL, {
      params,
      headers: { "x-api-key": CONSTANTS.APIKEY },
    });
    // // console.log(response);
    var shopdatas = response.data.data;
    setTotalShop(response.data.total);

    // console.log(shopdatas);
    setShops(shopdatas);
    // console.log("shopdata by wine!!!");
    setLoading(false);

    return response;
  };

  const handleLargeAreaSelectChange = (event) => {
    // since ID does not exist for country
    const choosed_largearea_name = event.target.value;
    // console.log("choosed largearea: " + choosed_largearea_name);
    var selectlargearea_object = largeareas.filter(
      (largearea) => largearea.name == choosed_largearea_name
    );

    // console.log(selectlargearea_object[0]);
    setSelectLargeArea(selectlargearea_object[0]);
    // console.log("selected largearea  ");
    fetchMiddleArea(selectlargearea_object[0].code);
  };

  const handleMiddleAreaSelectChange = (event) => {
    // since ID does not exist for country
    const choosed_middlearea_name = event.target.value;
    // console.log("choosed middlearea: " + choosed_middlearea_name);
    var selectmiddlearea_object = middleareas.filter(
      (middlearea) => middlearea.name == choosed_middlearea_name
    );

    // console.log(selectmiddlearea_object[0]);
    setSelectMiddleArea(selectmiddlearea_object[0]);
    // console.log("selected middlearea  ");
    fetchSmallArea(selectmiddlearea_object[0].code);
  };

  const handleSmallAreaSelectChange = (event) => {
    // since ID does not exist for country
    const choosed_smallarea_name = event.target.value;
    // console.log("choosed smallarea: " + choosed_smallarea_name);
    var selectsmallarea_object = smallareas.filter(
      (smallarea) => smallarea.name == choosed_smallarea_name
    );

    // console.log(selectsmallarea_object[0]);
    setSelectSmallArea(selectsmallarea_object[0]);
    // // console.log("selected smallarea  ");
  };

  const eachShop = (shopobject) => {
    var gazou;
    if (shopobject.hp_image_url_168) {
      gazou = shopobject.hp_image_url_168;
    } else if (shopobject.image_url_168) {
      gazou = shopobject.image_url_168;
    }
    return (
      <Link class="card-link" to={`/shop/${shopobject.id}`}>
        <figure class="card-figure">
          {gazou ? (
            <img src={gazou} />
          ) : (
            <img class="store-image" src={defaultshopimage} />
          )}
        </figure>

        <h2 class="card-title">{shopobject.name}</h2>
      </Link>
    );
  };

  return (
    <>
      <LoadingOverlay active={loading} spinner text="Loading...">
        <section class="section">
          <h2 class="title has-text-centered">{winename}</h2>
        </section>
        <section class="section has-text-centered serchsection mb-5">
          <div class="country">
            <img src={ic_area} width="35" class="mr-3" alt="" />
            <div class="select">
              <select
                value={selectlargearea.name}
                onChange={(event) => handleLargeAreaSelectChange(event)}
              >
                {largeareas.map((largearea) => {
                  return (
                    <option value={largearea.name}>{largearea.name}</option>
                  );
                })}
              </select>
            </div>
          </div>

          <div class="mt-3 area">
            <img src={ic_area} width="35" class="mr-3" alt="" />
            <div class="select">
              <select
                value={selectmiddlearea.name}
                onChange={(event) => handleMiddleAreaSelectChange(event)}
              >
                {middleareas.map((middlearea) => {
                  return (
                    <option value={middlearea.name}>{middlearea.name}</option>
                  );
                })}
              </select>
            </div>
          </div>
          <div class="variety mt-3">
            <img src={ic_area} width="35" class="mr-3" alt="" />
            <div class="select">
              <select
                value={selectsmallarea.name}
                onChange={(event) => handleSmallAreaSelectChange(event)}
              >
                {smallareas.map((smallarea) => {
                  return (
                    <option value={smallarea.name}>{smallarea.name}</option>
                  );
                })}
              </select>
            </div>
          </div>
          <br />
          <button
            class="button is-primary is-outlined is-large"
            type="submit"
            onClick={StartSearch}
          >
            <span class="icon">
              <i class="fas fa-search"></i>
            </span>
            <span>検索</span>
          </button>
        </section>

        <h2 class="title has-text-centered">{winename} が飲めるお店</h2>
        <hr />
        <section class="section pt-0">
          <h3 class="subtitle has-text-centered">店舗数：{totalshop}</h3>

          <div id="cardlayout-wrap">
            {shops.map((shop) => {
              return (
                <>
                  <Mobile>
                    <section class="card-list-middle">{eachShop(shop)}</section>
                  </Mobile>
                  <Tablet>
                    <section class="card-list-middle">{eachShop(shop)}</section>
                  </Tablet>
                  <Desktop>
                    <section class="card-list-large">{eachShop(shop)}</section>
                  </Desktop>
                  <LargeDesktop>
                    <section class="card-list-exlarge">
                      {eachShop(shop)}
                    </section>
                  </LargeDesktop>
                </>
              );
            })}
          </div>
        </section>
      </LoadingOverlay>
    </>
  );
};

export default SearchByWine;
