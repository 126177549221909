import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import hotpepper_logo from "../assets/hotpepper_logo.gif";
import wine_bottle from "../assets/wine_bottle.png";

import {
  Mobile,
  Tablet,
  Desktop,
  LargeDesktop,
  ExceptMobile,
} from "./Responsive";
import * as CONSTANTS from "../datas/constants";
import LoadingOverlay from "react-loading-overlay";
import defaultshopimage from "../assets/shop_default.png";

const Shop = () => {
  const [shopdetail, setShopDetail] = useState({});
  const [shopimage, setShopImage] = useState();
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  // console.log("showing id:");
  // console.log(id);

  const eachWine = (wineobject) => {
    return (
      <Link class="card-link" to={`/wine/${wineobject.id}`}>
        <figure class="card-figure">
          {wineobject.image_url ? (
            <img src={wineobject.image_url} />
          ) : (
            <img src={wine_bottle} />
          )}
        </figure>
        <h2 class="card-title" style={{ fontSize: 14 }}>
          {wineobject.name}
        </h2>
        <p class="has-text-right has-text-black" style={{ fontSize: 11 }}>
          登録日：{wineobject.updated_at.slice(0, 10)}
        </p>
      </Link>
    );
  };

  const eachWineHolizontalScroll = (wineobject) => {
    var winename_cut = wineobject.name.substring(0, 7) + "...";
    return (
      <td>
        <section class="card-list">
          <Link class="card-link" to={`/wine/${wineobject.id}`}>
            <figure class="card-figure">
              {wineobject.image_url ? (
                <img src={wineobject.image_url} class="scroll-img" />
              ) : (
                <img src={wine_bottle} class="scroll-img" />
              )}
            </figure>
            <h2 class="card-title" style={{ fontSize: 14 }}>
              {/* {wineobject.name} */}
              {winename_cut}
            </h2>
            <p class="has-text-right has-text-black" style={{ fontSize: 11 }}>
              登録日：{wineobject.updated_at.slice(0, 10)}
            </p>
          </Link>
        </section>
      </td>
    );
  };

  // for country
  useEffect(() => {
    const getShop = async () => {
      // const URL = "http://127.0.0.1:5000/api/v1/shops/" + id;
      const URL = `${CONSTANTS.BACKENDURL}/api/v1/shops/${id}`;
      // console.log(URL);
      // const response = await axios.get(URL, { params });
      const response = await axios.get(URL, {
        headers: { "x-api-key": CONSTANTS.APIKEY },
      });
      // console.log("sho detial");
      // console.log(response.data);
      setShopDetail(response.data);
      if (response.data.image_url_640) {
        setShopImage(response.data.image_url_640);
      } else if (response.data.hp_image_url_238) {
        setShopImage(response.data.hp_image_url_238);
      } else if (response.data.hp_image_url_168) {
        setShopImage(response.data.hp_image_url_168);
      }
      // Opening Modal
      // console.log("sho detial2");
    };

    getShop();
    setLoading(false);
  }, []);

  return (
    <>
      <LoadingOverlay active={loading} spinner text="Loading...">
        <div class="columns is-gapless">
          <div class="column">
            <Mobile>
              <section class="store-image-wrapper has-text-centered">
                {/* {<img src={shopdetail.hp_image_url_238} />} */}
                {shopimage ? (
                  <img class="store-image-mobile" src={shopimage} />
                ) : (
                  <img class="store-image-mobile" src={defaultshopimage} />
                )}
              </section>
            </Mobile>
            <ExceptMobile>
              <section class="section has-text-centered">
                {/* {<img src={shopdetail.hp_image_url_238} />} */}
                {shopimage ? (
                  <img class="store-image" src={shopimage} />
                ) : (
                  <img class="store-image" src={defaultshopimage} />
                )}
              </section>
            </ExceptMobile>
          </div>
          <div class="column">
            <section class="section">
              <h2 class="title has-text-centered">{shopdetail.name}</h2>

              <div class="icon-text">
                <span class="icon has-text-info">
                  <i class="fas fa-map-marker-alt"></i>
                </span>
                <span>Address</span>
              </div>
              <p class="block">
                {shopdetail.address}
                <br />
                <a href="">
                  <span class="icon is-size-7">
                    <i class="fas fa-external-link-alt"></i>
                  </span>
                  <span class="is-size-7">
                    <a
                      href={`https://www.google.co.jp/maps?q=${shopdetail.lat},${shopdetail.lng}`}
                      target="blank"
                    >
                      Google Mapで開く
                    </a>
                  </span>
                </a>
              </p>
              <div class="icon-text">
                <span class="icon has-text-info">
                  <i class="fas fa-glass-martini-alt"></i>
                </span>
                <span>Type of Restaurant</span>
              </div>
              <p class="block">{shopdetail.genre}</p>

              <div>
                <div class="icon-text">
                  <span class="icon has-text-info">
                    <i class="far fa-clock"></i>
                  </span>
                  <span>Opening Hour</span>
                </div>
                <p class="block">{shopdetail.open}</p>
              </div>
              <div class="mt-5 is-size-7">
                <p class="has-text-right">
                  店舗情報 by <img src={hotpepper_logo} alt="" />
                </p>
              </div>
            </section>
          </div>
        </div>
      </LoadingOverlay>
      <h2 class="title has-text-centered">登録ワイン</h2>
      <hr />
      <section class="container">
        <h3 class="subtitle ml-3">RED</h3>
        {shopdetail.wines && shopdetail.wines.red.length == 0 && (
          <section class="container">
            <p class="ml-3">登録されているワインはありません</p>
          </section>
        )}
        <div id="cardlayout-wrap">
          <table class="scroll-table">
            <tr>
              {shopdetail.wines &&
                shopdetail.wines.red.map((wine) => {
                  return (
                    <>
                      {eachWineHolizontalScroll(wine)}
                      {/* <Mobile>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Mobile>
                  <Tablet>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Tablet>
                  <Desktop>
                    <section class="card-list-large">{eachWine(wine)}</section>
                  </Desktop>
                  <LargeDesktop>
                    <section class="card-list-exlarge">
                      {eachWine(wine)}
                    </section>
                  </LargeDesktop> */}
                    </>
                  );
                })}
            </tr>
          </table>
        </div>
      </section>

      <hr />
      <section class="container">
        <h3 class="subtitle ml-3">WHITE</h3>
        {shopdetail.wines && shopdetail.wines.white.length == 0 && (
          <section class="container">
            <p class="ml-3">登録されているワインはありません</p>
          </section>
        )}
        <div id="cardlayout-wrap">
          <table class="scroll-table">
            <tr>
              {shopdetail.wines &&
                shopdetail.wines.white.map((wine) => {
                  return (
                    <>
                      {eachWineHolizontalScroll(wine)}

                      {/* <Mobile>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Mobile>
                  <Tablet>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Tablet>
                  <Desktop>
                    <section class="card-list-large">{eachWine(wine)}</section>
                  </Desktop>
                  <LargeDesktop>
                    <section class="card-list-exlarge">
                      {eachWine(wine)}
                    </section>
                  </LargeDesktop> */}
                    </>
                  );
                })}
            </tr>
          </table>
        </div>
      </section>
      <hr />
      <section class="container">
        <h3 class="subtitle ml-3">SPARKLING</h3>
        {shopdetail.wines && shopdetail.wines.sparkling.length == 0 && (
          <section class="container">
            <p class="ml-3">登録されているワインはありません</p>
          </section>
        )}
        <div id="cardlayout-wrap">
          <table class="scroll-table">
            <tr>
              {" "}
              {shopdetail.wines &&
                shopdetail.wines.sparkling.map((wine) => {
                  return (
                    <>
                      {eachWineHolizontalScroll(wine)}

                      {/* <Mobile>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Mobile>
                  <Tablet>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Tablet>
                  <Desktop>
                    <section class="card-list-large">{eachWine(wine)}</section>
                  </Desktop>
                  <LargeDesktop>
                    <section class="card-list-exlarge">
                      {eachWine(wine)}
                    </section>
                  </LargeDesktop> */}
                    </>
                  );
                })}
            </tr>
          </table>
        </div>
      </section>
      <hr />
      <section class="container">
        <h3 class="subtitle ml-3">ROSE</h3>
        {shopdetail.wines && shopdetail.wines.rose.length == 0 && (
          <section class="container">
            <p class="ml-3">登録されているワインはありません</p>
          </section>
        )}
        <div id="cardlayout-wrap">
          <table class="scroll-table">
            <tr>
              {shopdetail.wines &&
                shopdetail.wines.rose.map((wine) => {
                  return (
                    <>
                      {eachWineHolizontalScroll(wine)}

                      {/* <Mobile>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Mobile>
                  <Tablet>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Tablet>
                  <Desktop>
                    <section class="card-list-large">{eachWine(wine)}</section>
                  </Desktop>
                  <LargeDesktop>
                    <section class="card-list-exlarge">
                      {eachWine(wine)}
                    </section>
                  </LargeDesktop> */}
                    </>
                  );
                })}
            </tr>
          </table>
        </div>
      </section>
      <hr />
      <section class="container">
        <h3 class="subtitle ml-3">SWEET</h3>
        {shopdetail.wines && shopdetail.wines.sweet.length == 0 && (
          <section class="container">
            <p class="ml-3">登録されているワインはありません</p>
          </section>
        )}
        <div id="cardlayout-wrap">
          <table class="scroll-table">
            <tr>
              {shopdetail.wines &&
                shopdetail.wines.sweet.map((wine) => {
                  return (
                    <>
                      {eachWineHolizontalScroll(wine)}

                      {/* <Mobile>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Mobile>
                  <Tablet>
                    <section class="card-list-middle">{eachWine(wine)}</section>
                  </Tablet>
                  <Desktop>
                    <section class="card-list-large">{eachWine(wine)}</section>
                  </Desktop>
                  <LargeDesktop>
                    <section class="card-list-exlarge">
                      {eachWine(wine)}
                    </section>
                  </LargeDesktop> */}
                    </>
                  );
                })}
            </tr>
          </table>
        </div>
      </section>
    </>
  );
};

export default Shop;
