import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DefaultLayout } from "./templates/DefaultLayout";
// import getShop from "./components/WineSearch";
import SearchByWine from "./components/SearchByWine";
import Gmap from "./components/Gmap";
import Wine from "./components/Wine";
import Shop from "./components/Shop";
import WineSearch from "./components/WineSearch";
import "bulma/css/bulma.min.css";
import "./css/mystyles.css";
import ScrollToTop from "./components/scrollToTop";
ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route exact path="/" element={<DefaultLayout />}>
        <Route index element={<Gmap />} />
        <Route path="/map" element={<Gmap />} />
        <Route path="/shop/:id" element={<Shop />} />
        <Route path="/wine/:id" element={<Wine />} />
        <Route path="/search" element={<WineSearch />} />
        <Route path="/search_by_wine/:nameid" element={<SearchByWine />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

// ReactDOM.render(<App />, document.getElementById("root"));
