import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useParams, Link } from "react-router-dom";
import * as CONSTANTS from "../datas/constants";
import wine_bottle from "../assets/wine_bottle.png";

const Wine = () => {
  const [winedetail, setWineDetail] = useState({});
  const { id } = useParams();

  // console.log("showing wine id:");
  // console.log(id);

  // for country
  useEffect(() => {
    const getWine = async () => {
      // const URL = "http://127.0.0.1:5000/api/v1/wines/" + id;
      const URL = `${CONSTANTS.BACKENDURL}/api/v1/wines/${id}`;

      // console.log(URL);
      // const response = await axios.get(URL, { params });
      const response = await axios.get(URL, {
        headers: { "x-api-key": CONSTANTS.APIKEY },
      });
      // console.log("sho detial");
      // console.log(response.data);
      setWineDetail(response.data);

      // Opening Modal
      // console.log("sho detial2");
    };

    getWine();
  }, []);

  return (
    <>
      <div class="columns is-gapless">
        <div class="column">
          <section class="wine-section has-text-centered">
            {winedetail.image_url ? (
              <img src={winedetail.image_url} />
            ) : (
              <img class="store-image" src={wine_bottle} />
            )}
          </section>
        </div>
        <div class="column">
          <section class="wine-section">
            <h2 class="title has-text-centered">{winedetail.name_orig}</h2>
            <h2 class="subtitle has-text-centered">{winedetail.name}</h2>
            <div class="buttons is-centered mt-5">
              {winedetail.name ? (
                <Link
                  to={`/search_by_wine/${winedetail.id}:${winedetail.name}`}
                  class="button is-primary is-outlined ml-3"
                >
                  {" "}
                  <span class="icon">
                    <i class="fas fa-search"></i>
                  </span>
                  <span>飲めるお店を探す</span>
                </Link>
              ) : (
                <Link
                  to={`/search_by_wine/${winedetail.id}:${winedetail.name_orig}`}
                  class="button is-primary is-outlined ml-3"
                >
                  {" "}
                  <span class="icon">
                    <i class="fas fa-search"></i>
                  </span>
                  <span>飲めるお店を探す</span>
                </Link>
              )}
            </div>
            <table class="table is-fullwidth">
              <tbody>
                <tr>
                  <td class="has-text-weight-bold" width="100px">
                    国
                  </td>
                  <td>{winedetail.country}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold" width="100px">
                    生産者
                  </td>

                  <td>{winedetail.producer}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold" width="100px">
                    タイプ
                  </td>
                  <td>{winedetail.type_name}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold" width="100px">
                    品種
                  </td>
                  <td>{winedetail.primary_variety_name}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold" width="100px">
                    度数
                  </td>
                  <td>{winedetail.alcohol}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold" width="100px">
                    説明
                  </td>
                  <td>{winedetail.description}</td>
                </tr>
              </tbody>
            </table>
            <div class="buttons is-centered mt-5">
              <a href={`${winedetail.affiliate_url}`}>
                <button class="button is-primary is-outlined">
                  <span class="icon">
                    <i class="fas fa-search"></i>
                  </span>
                  <span>楽天でこのワインを探す</span>
                </button>
              </a>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Wine;
