import React, { useState, useEffect } from "react";
import axios from "axios";
import defaultwine from "../assets/wine_bottle.png";
import ReactLoading from "react-loading";
import { Link, useParams } from "react-router-dom";
// import ic_country from "../assets/ic_country.png";
import ic_variety from "../assets/ic_variety.png";
import ic_glass_white from "../assets/ic_glass_white.png";
import { Mobile, Tablet, Desktop, LargeDesktop } from "./Responsive";
import * as CONSTANTS from "../datas/constants";
import LoadingOverlay from "react-loading-overlay";

const WineSearch = () => {
  const [done, setDone] = useState(false);
  const [NoWine, setNoWine] = useState("");
  const [showTotal, setshowTotal] = useState(0);
  const [readPage, setPage] = useState(1);
  const [searchparam, setParams] = useState({});
  const [searchword, setSearchWord] = useState("");
  const [countryflag, setCountryFlag] = useState("ic_country");
  const [isLoadMore, setisLoadMore] = useState(true);

  const [winetype, setWineType] = useState([
    "未選択",
    "rose",
    "white",
    "red",
    "sparkling",
    "sweet",
  ]);
  const [selectwinetype, setSelectWineType] = useState();
  const [loading, setLoading] = useState(true);

  const [countries, setCountries] = useState([{ name: "未選択", code: "" }]);
  const [selectcountry, setSelectCountries] = useState({
    name: "",
    code: "",
  });

  const [varieties, setVarieties] = useState([
    { id: -1, name: "未選択", is_major: true, sort_order: -1 },
  ]);
  const [selectvarieties, setSelectVarieties] = useState({
    id: 0,
    name: "",
    is_major: true,
    sort_order: 0,
  });
  const [wines, setWines] = useState([]);

  // for varaieties
  useEffect(() => {
    var varaeities_all;
    const fetchVaraities = async () => {
      var result = await axios.get(
        `${CONSTANTS.BACKENDURL}/api/v1/varieties?lang=ja`,
        {
          headers: { "x-api-key": CONSTANTS.APIKEY },
        }
      );
      varaeities_all = [...varieties, ...result.data.data];
      setVarieties(varaeities_all);
      var current_page = result.data.current_page;
      var last_page = result.data.last_page;

      while (current_page <= last_page) {
        if (current_page === 1) {
          current_page++;
          // console.log("current page" + current_page);
          continue;
        }
        // console.log(varaeities_all);
        result = [];
        result = await axios.get(
          `${CONSTANTS.BACKENDURL}/api/v1/varieties?lang=ja&page=${current_page}`,
          {
            headers: { "x-api-key": CONSTANTS.APIKEY },
          }
        );
        // console.log(varieties[0]);
        // console.log("Varietieselseeeeeeeeeee!!!");
        varaeities_all = [...varaeities_all, ...result.data.data];
        setVarieties(varaeities_all);
        current_page++;
      }
      setDone(true);
      setLoading(false);
    };

    fetchVaraities();
  }, []);

  // for country
  useEffect(() => {
    var country_all;
    const fetchCountries = async () => {
      const result = await axios.get(
        `${CONSTANTS.BACKENDURL}/api/v1/countries`,
        {
          headers: { "x-api-key": CONSTANTS.APIKEY },
        }
      );
      country_all = countries.concat(result.data.data);
      // console.log(country_all);
      // // console.log(varieties[0]);
      // console.log("Country!!!");
      setCountries(country_all);
    };

    fetchCountries();
  }, []);

  // additional search
  const loadMore = async () => {
    setNoWine("");
    var params = searchparam["params"];

    params["page"] = readPage;

    // console.log("param for read more");
    // console.log(params);
    // const URL = "http://127.0.0.1:5000/api/v1/wines";
    const URL = `${CONSTANTS.BACKENDURL}/api/v1/wines`;
    const response = await axios.get(URL, {
      params,
      headers: { "x-api-key": CONSTANTS.APIKEY },
    });
    // console.log(response);

    var winedata = response.data.data;
    if (winedata.length == 0) {
      setNoWine("登録されているワインはありません");
    }

    // console.log(winedata);
    setWines([...wines, ...winedata]);
    // console.log("setWines !!!");

    if (response.data.next_page_url) {
      setisLoadMore(true);
      // console.log(readPage);
      // console.log("readpage--");
      var rpage = readPage + 1;
      setPage(rpage);
      // console.log(rpage);
    } else {
      setisLoadMore(false);
    }
  };

  // initial / reset search
  const StartSearch = async () => {
    setNoWine("");
    var params = {};
    setPage(1);

    // console.log("check search wine type");
    // console.log(selectwinetype);
    if (selectwinetype == "未選択" || !selectwinetype) {
      alert("ワインの種類を選択してください");
      return;
    }
    setLoading(true);

    params["type"] = selectwinetype;

    if (selectvarieties.id) {
      if (selectvarieties.id != -1) {
        params["variety"] = selectvarieties.id;
      }
    }
    if (selectcountry.name && selectcountry.code != "") {
      params["country"] = selectcountry.code;
    }
    if (searchword) {
      params["name"] = searchword;
    }

    setParams({ params });

    params["page"] = readPage;
    // console.log("============");
    // console.log(params);

    const response = await axios.get(`${CONSTANTS.BACKENDURL}/api/v1/wines`, {
      params,
      headers: { "x-api-key": CONSTANTS.APIKEY },
    });
    // console.log(response);

    var winedata = response.data.data;
    setshowTotal(response.data.total);
    if (winedata.length == 0) {
      setNoWine("登録されているワインはありません");
      setshowTotal(0);
    }
    // console.log(winedata);
    setWines([...winedata]);
    // console.log("setWines!!!");

    if (response.data.next_page_url) {
      // console.log(readPage);
      // console.log("readpage--");
      var rpage = readPage + 1;
      setPage(rpage);
      // console.log(rpage);
    }
    setLoading(false);
  };

  const handleWineSelectChange = (event) => {
    const choose_wine_varieties_name = event.target.value;
    // console.log(choose_wine_varieties_name);
    var variety_choosed_object = varieties.filter(
      (variety) => variety.name == choose_wine_varieties_name
    );
    // console.log(variety_choosed_object[0]);
    setSelectVarieties(variety_choosed_object[0]);
    // console.log("selected wine varieties ");
  };

  const handleWineTypeChange = (event) => {
    const choose_wine_type_name = event.target.value;
    // console.log(choose_wine_type_name);
    setSelectWineType(choose_wine_type_name);
    // console.log("selected wine type ");
  };

  const handleCountrySelectChange = (event) => {
    // since ID does not exist for country
    const choosed_country_name = event.target.value;
    // console.log("choosed country: " + choosed_country_name);
    var selectcountry_object = countries.filter(
      (country) => country.name == choosed_country_name
    );

    // console.log(selectcountry_object[0]);
    setSelectCountries(selectcountry_object[0]);
    // console.log("selected country  ");
    var lower_countryflag = selectcountry_object[0].code.toLowerCase();

    if (lower_countryflag) {
      setCountryFlag(lower_countryflag);
    } else {
      setCountryFlag("ic_country");
    }
  };

  const eachWine = (wineobject) => {
    return (
      <Link class="card-link" to={`/wine/${wineobject.id}`}>
        <figure class="card-figure">
          {wineobject.image_url ? (
            <img src={wineobject.image_url} />
          ) : (
            <img src={defaultwine} />
          )}{" "}
        </figure>
        <h2 class="card-title">{wineobject.name}</h2>
      </Link>
    );
  };

  const handleChange = (event) => {
    var word = event.target.value;
    setSearchWord(word);
  };

  return (
    <>
      <LoadingOverlay active={loading} spinner text="Loading...">
        <section class="section">
          <h2 class="title has-text-centered">ワインを検索する</h2>
        </section>{" "}
        {/* {!done ? (
        <ReactLoading
          type={"bars"}
          color={"#03fc4e"}
          height={100}
          width={100}
        />
      ) : (
        <div></div>
      )} */}
        <section class="section has-text-centered serchsection mb-5">
          <div class="control">
            <input
              class="input wine-search"
              type="text"
              placeholder="ワイン名・キーワード"
              value={searchword}
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>

          <div class="variety mt-5 mb-2">
            <img src={ic_variety} width="40" class="mr-3" alt="" />
            <div class="select">
              <select
                value={selectvarieties && selectvarieties["name"]}
                onChange={(event) => handleWineSelectChange(event)}
              >
                {varieties.map((winevaraiety) => {
                  return (
                    <option value={winevaraiety.name}>
                      {winevaraiety.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {/* <p>国</p> */}
          <div class="country">
            <span id="Box1" class="my-5">
              <img
                src={`../country/${countryflag}.png`}
                width="40"
                class="mr-3 mt-2"
                alt=""
              />
            </span>
            <div class="select">
              <div class="col-auto">
                <select
                  class="form-control"
                  value={selectcountry.name}
                  onChange={(event) => handleCountrySelectChange(event)}
                >
                  {countries.map((country) => {
                    return <option value={country.name}>{country.name}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
          {/* <p>ワインのタイプ</p> */}
          <div class="mt-4 area">
            <img src={ic_glass_white} width="20" class="mr-3" alt="" />
            <div class="select">
              <select
                value={selectwinetype}
                onChange={(event) => handleWineTypeChange(event)}
              >
                {winetype.map((winet) => {
                  return <option value={winet}>{winet}</option>;
                })}
              </select>
            </div>
          </div>

          <div class="buttons is-centered mt-5">
            <button
              type="submit"
              onClick={StartSearch}
              class="button is-primary is-outlined is-large"
            >
              <span class="icon">
                <i class="fas fa-search"></i>
              </span>
              <span>検索</span>
            </button>
          </div>
        </section>
      </LoadingOverlay>
      <h2 class="title has-text-centered">ワイン検索結果</h2>
      <hr />
      <section class="section pt-0">
        <h3 class="subtitle">ワイン数：{showTotal}</h3>
        <div id="cardlayout-wrap">
          {wines.map((wine, index) => {
            return (
              <>
                <Mobile>
                  <section class="card-list-middle">{eachWine(wine)}</section>
                </Mobile>
                <Tablet>
                  <section class="card-list-middle">{eachWine(wine)}</section>
                </Tablet>
                <Desktop>
                  <section class="card-list-large">{eachWine(wine)}</section>
                </Desktop>
                <LargeDesktop>
                  <section class="card-list-exlarge">{eachWine(wine)}</section>
                </LargeDesktop>
              </>
            );
          })}
        </div>
      </section>
      <section class="section">
        {readPage != 1 ? (
          <center>
            {/* <button class="has-text-centered" type="submit" onClick={loadMore}>
              更に読み込む
            </button> */}
            {isLoadMore && (
              <button
                class="button is-primary is-outlined is-large"
                type="submit"
                onClick={loadMore}
              >
                <span class="icon">
                  <i class="fas fa-search"></i>
                </span>
                <span>更に読み込む</span>
              </button>
            )}
          </center>
        ) : (
          <div></div>
        )}
        {NoWine}
      </section>
    </>
  );
};

export default WineSearch;
