const WhatGirouette = () => {
  return (
    <div class="columns">
      <div class="column">
        <section class="section-top">
          <h1 class="title has-text-centered">giroette（ジロエット）とは？</h1>
          <h2 class="subtitle has-text-centered">
            置いてるワインからバルを探そう！！
          </h2>
          <p>ワインには沢山の種類があります。</p>
          <br />
          <p>
            ただ、『ここの国のワインが飲んでみたいな！』『シャルドネのワインが飲んでみたいな！』と思ってもそのワインがどこのお店に置いてあるかの情報を得ることは難しいです。
          </p>
          <br />
          <p>
            そんな時にこのウェブサービスを使うと簡単にワインの種類からお店を探すことができます！
          </p>
          <br />
          <p>
            現在の検索範囲は関東、ワインの検索は種類(赤、白、ロゼ等)・国名・地域・葡萄の品種となっています。良いワインライフをお楽しみください
          </p>
        </section>
      </div>
      <div class="column">
        <section class="section-top">
          <h2 class="title has-text-centered">アプリ版もあります</h2>
          <p>
            ウェブサービスだけでなく、アプリ版も用意しています。下記リンクからアプリをダウンロードすることで、いつでも気軽にスマホから検索することができます
          </p>
          <br />
          <div class="appreach">
            <img
              src="https://is1-ssl.mzstatic.com/image/thumb/Purple125/v4/5a/60/91/5a609164-da9d-23cf-329b-36e2b1078950/source/512x512bb.jpg"
              alt="Girouette (ジロエット)"
              class="appreach__icon"
            />
            <div class="appreach__detail">
              <p class="appreach__name">Girouette (ジロエット)</p>
              <p class="appreach__info">
                <span class="appreach__developper">Jun Kageyama</span>
                <span class="appreach__price">無料</span>
                <span class="appreach__posted">
                  posted with
                  <a
                    href="https://mama-hack.com/app-reach/"
                    title="アプリーチ"
                    target="_blank"
                    rel="nofollow"
                  >
                    アプリーチ
                  </a>
                </span>
              </p>
            </div>
            <div class="appreach__links">
              <a
                href="https://apps.apple.com/jp/app/girouette-%25E3%2582%25B8%25E3%2583%25AD%25E3%2582%25A8%25E3%2583%2583%25E3%2583%2588/id1531709362?uo=4"
                rel="nofollow"
                class="appreach__aslink"
              >
                <img src="https://nabettu.github.io/appreach/img/itune_ja.svg" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=jp.girouettes.la_girouette"
                rel="nofollow"
                class="appreach__gplink"
              >
                <img src="https://nabettu.github.io/appreach/img/gplay_ja.png" />
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default WhatGirouette;
