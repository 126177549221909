import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Circle,
} from "@react-google-maps/api";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
// import ic_country from "../assets/ic_country.png";
import mylocation from "../assets/my-location-button.png";
import ic_variety from "../assets/ic_variety.png";
import ic_area from "../assets/ic_area.png";
import WhatGirouette from "./WhatGirouette";
import * as CONSTANTS from "../datas/constants";
import LoadingOverlay from "react-loading-overlay";

const containerStyle = {
  width: "100%",
  height: "70vh",
};

const circleOptions = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.4,
  strokeWeight: 1,
  fillColor: "#FF0000",
  fillOpacity: 0.2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};
const SWEETETC = "SWEET";

const Gmap = () => {
  const [mapref, setMapRef] = useState(null);
  const [zoomsize, setZoomSize] = useState(16);
  const [SelectedValue, setSelectedValue] = useState("");
  const [center, setCenter] = useState({ lat: 35.7777, lng: 139.7206 });
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState({});
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [countryflag, setCountryFlag] = useState("ic_country");
  const [currentPosition, setCurrentPosition] = useState(false);
  const [winetype, setWineType] = useState([
    {
      name: "RED",
      is_checked: "",
      is_selected: false,
      color: "btnRED_wrap",
    },
    {
      name: "SPARKLING",
      is_checked: "",
      is_selected: false,
      color: "btnSPARKLING_wrap",
    },
    {
      name: "WHITE",
      is_checked: "",
      is_selected: false,
      color: "btnWHITE_wrap",
    },
    {
      name: "ROSE",
      is_checked: "",
      is_selected: false,
      color: "btnROSE_wrap",
    },
    {
      name: SWEETETC,
      is_checked: "",
      is_selected: false,
      color: "btnSWEET_wrap",
    },
  ]);
  const [varieties, setVarieties] = useState([
    { id: -1, name: "未選択", is_major: true, sort_order: -1 },
  ]);
  const [selectvarieties, setSelectVarieties] = useState({
    id: 0,
    name: "",
    is_major: true,
    sort_order: 0,
  });

  const [areas, setAreas] = useState([{ id: -1, name: "未選択", code: "" }]);

  const [selectareas, setSelectAreas] = useState({
    id: 0,
    name: "",
    country_code: "",
  });

  const [countries, setCountries] = useState([{ name: "未選択", code: "" }]);
  const [selectcountry, setSelectCountries] = useState({
    name: "",
    code: "",
  });

  useEffect(() => {
    var varaeities_all;
    const fetchVaraities = async () => {
      var result = await axios.get(
        `${CONSTANTS.BACKENDURL}/api/v1/varieties?lang=ja`,
        {
          headers: { "x-api-key": CONSTANTS.APIKEY },
        }
      );
      varaeities_all = [...varieties, ...result.data.data];
      setVarieties(varaeities_all);
      var current_page = result.data.current_page;
      var last_page = result.data.last_page;

      while (current_page <= last_page) {
        if (current_page === 1) {
          current_page++;
          // console.log("current page" + current_page);
          continue;
        }
        // console.log(varaeities_all);
        result = [];
        result = await axios.get(
          `${CONSTANTS.BACKENDURL}/api/v1/varieties?lang=ja&page=${current_page}`,
          {
            headers: { "x-api-key": CONSTANTS.APIKEY },
          }
        );
        // // console.log(varieties[0]);
        // console.log("Varietieselseeeeeeeeeee!!!");
        varaeities_all = [...varaeities_all, ...result.data.data];
        setVarieties(varaeities_all);
        current_page++;
      }
      setDone(true);
      setLoading(false);
    };

    fetchVaraities();
  }, []);

  // for area
  useEffect(() => {
    var areas_all;
    const fetchAreas = async () => {
      const result = await axios.get(`${CONSTANTS.BACKENDURL}/api/v1/areas`, {
        headers: { "x-api-key": CONSTANTS.APIKEY },
      });
      areas_all = areas.concat(result.data.data);
      // console.log(areas_all);
      // // console.log(varieties[0]);
      // console.log("Areass!!!");
      setAreas(areas_all);
    };

    fetchAreas();
  }, []);

  // for country
  useEffect(() => {
    var country_all;
    const fetchCountries = async () => {
      const result = await axios.get(
        `${CONSTANTS.BACKENDURL}/api/v1/countries?lang=ja`,
        { headers: { "x-api-key": CONSTANTS.APIKEY } }
      );
      country_all = countries.concat(result.data.data);
      // console.log(country_all);
      // // console.log(varieties[0]);
      // console.log("Country!!!");
      setCountries(country_all);
    };

    fetchCountries();
  }, []);

  const onSelect = (item) => {
    setSelected(item);
  };

  const handleOnLoad = (map) => {
    setMapRef(map);
  };

  const handleGetCurrentPosition = () => {
    setLoading(true);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        // console.log(position.coords);
        setCurrentPosition(true);
        setLoading(false);
      },
      (err) => {
        // console.log(err);
        alert(
          "現在地を表示できません！表示するためにはブラウザの位置情報の共有許可をしてください"
        );
        setCurrentPosition(false);
        // 赤羽に移動させる。
        setCenter({ lat: 35.7777, lng: 139.7206 });
        setLoading(false);
      }
      // { maximumAge: 5000, timeout: 5000 }
      // { maximumAge: 50000, timeout: 8000 }
    );
  };

  const handleZoomChanged = () => {
    if (mapref) {
      const newZoom = mapref.getZoom();
      // console.log(newZoom);
      setZoomSize(newZoom);
    }
  };
  const handleCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter();
      // console.log(newCenter.lat());
      // console.log(newCenter.lng());
      // setCenter({
      //   lat: newCenter.lat(),
      //   lng: newCenter.lng(),
      // });
    }
  };

  const stationData = {
    赤羽: { lat: 35.7777, lng: 139.7206 },
    恵比寿: { lat: 35.6467, lng: 139.7101 },
    渋谷: { lat: 35.658, lng: 139.7016 },
    新宿: { lat: 35.6896, lng: 139.7006 },
    池袋: { lat: 35.7295, lng: 139.7109 },
    有楽町: { lat: 35.6749, lng: 139.7628 },
    横浜: { lat: 35.4658, lng: 139.6223 },
    大宮: { lat: 35.9064, lng: 139.6239 },
    赤坂: { lat: 35.6722, lng: 139.7364 },
    中野: { lat: 35.7054, lng: 139.6658 },
    東京: { lat: 35.6812, lng: 139.7671 },
    品川: { lat: 35.6285, lng: 139.7388 },
    浦和: { lat: 35.8587, lng: 139.6574 },
  };

  const handleSelectChange = (event) => {
    const location = event.target.value;
    // console.log(location);

    setSelectedValue(location);
    setCenter({
      lat: stationData[location].lat,
      lng: stationData[location].lng,
    });
    // console.log("before range");
    getrange();
  };

  // return by hotpepper range by meter
  const getrange = () => {
    const R = Math.PI / 180;
    var distance = 0;
    if (mapref) {
      var latlngBounds = mapref.getBounds();
      var swLatlng = latlngBounds.getSouthWest();
      var lat2 = swLatlng.lat();
      var lat1 = center.lat;
      var lng1 = center.lng;
      var lng2 = center.lng;

      lat1 *= R;
      lng1 *= R;
      lat2 *= R;
      lng2 *= R;
      // this should be kilo meter
      distance =
        6371 *
        Math.acos(
          Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng1 - lng2) +
            Math.sin(lat1) * Math.sin(lat2)
        );
    }
    var range = 1000;

    switch (true) {
      case distance < 0.3:
        range = 300;
        break;
      case distance >= 0.3 && distance < 0.5:
        range = 500;
        break;
      case distance >= 0.5 && distance < 1:
        range = 1000;
        break;
      case distance >= 1 && distance < 2:
        range = 2000;
        break;
      case distance >= 2:
        range = 3000;
        break;
      default:
        range = 3;
    }
    // console.log(range);
    return range;
  };

  const StartSearch = async () => {
    setLoading(true);
    var selected_wt = "";
    winetype.forEach((stored_wine) => {
      if (stored_wine.is_selected == true) {
        if (stored_wine.name == SWEETETC) {
          selected_wt = selected_wt + "sweet" + "," + "unknown,";
        } else {
          var winetypename_lower = stored_wine.name.toLowerCase();

          selected_wt = selected_wt + winetypename_lower + ",";
        }
      }
    });

    // console.log("wt!!!!!!!!!");

    var wtlist = selected_wt;
    // console.log(wtlist);

    if (mapref) {
      var CurrentCenter = mapref.getCenter();
    }

    const params = {
      lat: CurrentCenter.lat(),
      lng: CurrentCenter.lng(),
      range: getrange(),
    };
    if (wtlist) {
      params["wine_types"] = wtlist;
    }
    if (selectvarieties.id) {
      params["wine_varieties"] = selectvarieties.id;
    }
    if (selectcountry.name && selectcountry.code != "") {
      params["wine_country"] = selectcountry.code;
    }
    if (selectareas.name && selectareas.id != -1) {
      params["wine_areas"] = selectareas.id;
    }

    const response = await axios.get(
      `${CONSTANTS.BACKENDURL}/api/v1/shops?lang=ja`,
      { params, headers: { "x-api-key": CONSTANTS.APIKEY } }
    );
    // // console.log(response);
    var shopdatas = response.data.data;
    // console.log(shopdatas);
    setMarkers(shopdatas);
    // console.log("setMakrers!!!");
    //  return response;
    setLoading(false);
  };

  const handleCheckChange = (winet_object) => {
    const winet = winet_object;
    // console.log("WINET clicked");
    // console.log(winet);
    var winet_to_update = [];
    winetype.forEach((each_wine) => {
      if (each_wine.name == winet.name) {
        if (each_wine.is_selected == true) {
          winet.is_selected = false;
          winet.is_checked = "";
        } else {
          winet.is_selected = true;
          winet.is_checked = "checked";
        }
        winet_to_update.push(winet);
      } else {
        winet_to_update.push(each_wine);
      }
    });
    // console.log("set Wine Type");
    // console.log(winet_to_update);
    setWineType(winet_to_update);
  };

  const handleWineSelectChange = (event) => {
    const choose_wine_varieties_name = event.target.value;
    // console.log(choose_wine_varieties_name);
    var variety_choosed_object = varieties.filter(
      (variety) => variety.name == choose_wine_varieties_name
    );
    // console.log(variety_choosed_object[0]);
    setSelectVarieties(variety_choosed_object[0]);
    // console.log("selected wine varieties ");
  };

  const handleAreaSelectChange = (event) => {
    const choosed_area_name = event.target.value;
    // this is id for area
    // console.log(choosed_area_name);

    // console.log("gathered area");
    // console.log(areas);
    var selectarea_object = areas.filter(
      (area) => area.name == choosed_area_name
    );
    // console.log(selectarea_object);
    // console.log(selectarea_object[0]);
    setSelectAreas(selectarea_object[0]);
    // console.log("selected areas  ");
    // console.log(selectareas);
  };

  const handleCountrySelectChange = (event) => {
    // since ID does not exist for country
    const choosed_country_name = event.target.value;
    // console.log("choosed country: " + choosed_country_name);
    var selectcountry_object = countries.filter(
      (country) => country.name == choosed_country_name
    );

    // console.log(selectcountry_object[0]);
    setSelectCountries(selectcountry_object[0]);
    // console.log("selected country  ");

    var lower_countryflag = selectcountry_object[0].code.toLowerCase();
    if (lower_countryflag) {
      setCountryFlag(lower_countryflag);
    } else {
      setCountryFlag("ic_country");
    }
  };

  const RefreshPage = () => {
    window.location.reload(false);
  };

  const compareAreAndCountry = () => {};

  // console.log(winetype);

  return (
    <>
      <section class="hero">
        <div class="hero-head">
          <LoadingOverlay active={loading} spinner text="Loading...">
            <LoadScript googleMapsApiKey="AIzaSyBLozculR5dnnJOJxPpQV-zhz5iNeHps-M">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                onLoad={handleOnLoad}
                zoom={zoomsize}
                onCenterChanged={handleCenterChanged}
                onZoomChanged={handleZoomChanged}
              >
                {currentPosition && (
                  <Circle center={center} radius={70} options={circleOptions} />
                )}
                {markers.map((shop) => {
                  var loc = { lat: shop.lat, lng: shop.lng };
                  shop["location"] = loc;
                  return (
                    <Marker
                      key={shop.id}
                      position={loc}
                      onClick={() => onSelect(shop)}
                    />
                  );
                })}
                {selected.location && (
                  <InfoWindow
                    position={selected.location}
                    clickable={true}
                    onCloseClick={() => setSelected({})}
                  >
                    <div className="shopmodal" style={{ padding: 5 }}>
                      <p style={{ fontWeight: "bold", fontSize: 14 }}>
                        {selected.name}
                      </p>
                      <Link to={`/shop/${selected.id}`} target="_blank">
                        詳細はこちら
                      </Link>
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>
          </LoadingOverlay>
        </div>
      </section>
      {/* <br />
      <button
        onClick={() => {
          handleGetCurrentPosition();
        }}
      >
        現在地を表示
      </button> */}
      <div class="mylocation" onClick={() => handleGetCurrentPosition()}>
        <img src={mylocation} alt="" />
      </div>
      <section class="station has-text-centered is-centered">
        <div class="select is-rounded">
          <select value={SelectedValue} onChange={handleSelectChange}>
            {Object.keys(stationData).map((station) => {
              return <option value={station}>{station}</option>;
            })}
          </select>
        </div>
      </section>
      {/* <p>ワインのタイプ</p> */}
      <section class="section has-text-centered serchsection mb-3">
        <div class="buttons is-centered mt-5">
          <br />
          <button
            class="button is-black is-outlined mr-3"
            type="submit"
            onClick={RefreshPage}
          >
            クリア
          </button>
          <button
            class="button is-primary is-outlined is-large"
            type="submit"
            onClick={StartSearch}
          >
            <span class="icon">
              <i class="fas fa-search"></i>
            </span>
            <span>検索</span>
          </button>
        </div>
        <div class="is-centered mb-5">
          {winetype.map((winet) => {
            // console.log(winet);
            return (
              <div class={winet.color}>
                <input
                  type="checkbox"
                  value={winet.name}
                  checked={winet.is_checked}
                />
                <label
                  onClick={() => {
                    handleCheckChange(winet);
                  }}
                >
                  <span class="icon">
                    <i class="fas fa-wine-glass-alt"></i>
                  </span>
                  {winet.name}
                </label>
              </div>
            );
          })}
        </div>
        <div class="country">
          {/* <p>国</p> */}
          <span id="Box1" class="my-5">
            {/* <img src={ic_country} width="40" class="mr-3 mt-2" alt="" /> */}
            <img
              src={`../country/${countryflag}.png`}
              width="40"
              class="mr-3 mt-2"
              alt=""
            />
          </span>
          <div class="select">
            <div class="col-auto">
              <select
                class="form-control"
                value={selectcountry.name}
                onChange={(event) => handleCountrySelectChange(event)}
              >
                {countries.map((country) => {
                  return <option value={country.name}>{country.name}</option>;
                })}
              </select>
            </div>
          </div>
        </div>

        <div class="mt-3 area">
          <img src={ic_area} width="40" class="mr-3" alt="" />
          <div class="select">
            {/* <p>エリア</p> */}
            <select
              value={selectareas.name}
              onChange={(event) => handleAreaSelectChange(event)}
            >
              {areas.map((area) => {
                return <option value={area.name}>{area.name}</option>;
              })}
            </select>
          </div>
        </div>

        <div class="variety mt-3">
          <img src={ic_variety} width="40" class="mr-3" alt="" />
          <div class="select">
            {/* <p>ワインのバラエティ</p> */}
            <select
              value={selectvarieties && selectvarieties["name"]}
              onChange={(event) => handleWineSelectChange(event)}
            >
              {varieties.map((winevaraiety) => {
                return (
                  <option value={winevaraiety.name}>{winevaraiety.name}</option>
                );
              })}
            </select>
          </div>
        </div>
      </section>
      <WhatGirouette />
    </>
  );
};

export default Gmap;
