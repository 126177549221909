import MediaQuery, { useMediaQuery } from "react-responsive";

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const LargeDesktop = ({ children }) => {
  const isLargeDesktop = useMediaQuery({ minWidth: 1200 });
  return isLargeDesktop ? children : null;
};
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  return isDesktop ? children : null;
};

const ExceptMobile = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};

export { Mobile, Tablet, Desktop, LargeDesktop, ExceptMobile };
