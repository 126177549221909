import React from "react";
import { Link } from "react-router-dom";

var ThisYear = new Date().getFullYear();
const Footer = () => {
  return (
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <i class="far fa-copyright"></i>
          {ThisYear}&nbsp;&nbsp;girouette（ジロエット）
        </p>
      </div>
    </footer>
  );
};

export default Footer;
